import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import NavButtons from 'components/Motion_Detection/NavButtons';
import ForumBox from 'components/Motion_Detection/FTP_Server_Setup/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "FTP Server Installation",
  "path": "/Motion_Detection/FTP_Server_Setup/Install_FileZilla_Server/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Setting up a FTP server on your Windows Computer",
  "image": "../../MD_SearchThumb_FTPServerSetup.png",
  "social": "/images/Search/MD_SearchThumb_FTPServerSetup.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-FTP-Server_white.webp",
  "chapter": "Motion Detection"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const EuiSpacer = makeShortcode("EuiSpacer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='FTP Server Installation' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Setting up a FTP server on your Windows Computer' image='/images/Search/MD_SearchThumb_FTPServerSetup.png' twitter='/images/Search/MD_SearchThumb_FTPServerSetup.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/FTP_Server_Setup/FileZilla_Server_Installieren/' locationFR='/fr/Motion_Detection/FTP_Server_Setup/Install_FileZilla_Server/' crumbLabel="FTP Server" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "installing-a-filezilla-server-on-your-windows-computer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#installing-a-filezilla-server-on-your-windows-computer",
        "aria-label": "installing a filezilla server on your windows computer permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installing a Filezilla server on your Windows Computer`}</h2>
    <p>{`Start the installation of the software after `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/FTP_Server_Setup/"
      }}>{`Downloading the Setup File`}</a>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "./Install_FileZilla_Server/Filezilla_install1.png",
        "alt": "Filezilla FTP Server Installation"
      }}></img></p>
    <p>{`Install the FileZilla Server.`}</p>
    <p><img parentName="p" {...{
        "src": "./Install_FileZilla_Server/Filezilla_install2.png",
        "alt": "Filezilla FTP Server Installation"
      }}></img></p>
    <p>{`Choose the Standard Installation.`}</p>
    <p><img parentName="p" {...{
        "src": "./Install_FileZilla_Server/Filezilla_install3.png",
        "alt": "Filezilla FTP Server Installation"
      }}></img></p>
    <p>{`Choose an installation path for the server.`}</p>
    <p><img parentName="p" {...{
        "src": "./Install_FileZilla_Server/Filezilla_install4.png",
        "alt": "Filezilla FTP Server Installation"
      }}></img></p>
    <p>{`Make sure that the FTP service is automatically started with Windows.`}</p>
    <p><img parentName="p" {...{
        "src": "./Install_FileZilla_Server/Filezilla_install5.png",
        "alt": "Filezilla FTP Server Installation"
      }}></img></p>
    <p>{`Decide if you want to start the server automatically with Windows or if you want to start it manually.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      